import React from "react";
import { getFileCategory, getCategoryColor } from "src/common/mime-types.helpers.js";
import { getExt } from "src/documents/documents.helpers.js";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import { shape, bool, string, object } from "prop-types";

export default function DocIcon({ doc, isPreview, color: iconColor, style, isClientPortal = false }) {
  // Try to categorize the doc based on mime type, then extension
  const isLocked = doc?.locked;
  const fileType = getFileCategory(doc?.type) || getFileCategory(doc?.mimetype) || getFileCategory(getExt(doc?.name));
  let fileIcon = fileType && fileType !== "word-doc" && fileType !== "other" ? `file-${fileType}` : "file-document";
  const hasChildren = doc?.hasFolders || doc?.hasFiles;

  let color = "var(--cps-color-default-icon)";
  let previewIconColor = "var(--cp-color-app-secondary-text)";
  let tooltipText = "";

  if (isLocked && !isClientPortal) {
    fileIcon = "misc-padlock";
    color = isPreview ? previewIconColor : color;
  } else if (
    doc?.doc_type === "folder" ||
    doc?.is_folder ||
    doc?.mimetype === "folder" ||
    doc?.doc_type === "folder" ||
    doc[0]?.is_folder
  ) {
    fileIcon = `folder${doc?.is_open && hasChildren ? "-open" : ""}`;
    color = isPreview ? previewIconColor : color;
    if (doc?.retention_rule) {
      const { duration_years, archive_after_retention } = doc?.retention_rule;
      fileIcon = `folder-clock${doc?.is_open && hasChildren ? "-open" : "-closed"}`;
      const duration_type_string = duration_years !== 1 ? "years" : "year";
      tooltipText = `Retain for ${duration_years} ${duration_type_string} ${
        archive_after_retention ? `then archive` : ``
      }`;
    }
  } else {
    color = iconColor || getCategoryColor(fileType, isPreview ? previewIconColor : "var(--cps-color-monsoon)");
  }

  return (
    <span style={{ color, ...style }}>
      <CpTooltip disabled={!doc?.retention_rule} text={tooltipText}>
        <CpIcon name={fileIcon} />
      </CpTooltip>
    </span>
  );
}

DocIcon.propTypes = {
  doc: shape({
    locked: bool,
    type: string,
    mimetype: string,
    name: string,
    hasFolders: bool,
    hasFiles: bool,
  }),
  isClientPortal: bool,
  isPreview: bool,
  color: string,
  style: object,
};
