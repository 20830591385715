import canopyUrls from "canopy-urls!sofe";
import {
  cloneDeep,
  find,
  findIndex,
  get,
  isEmpty,
  last,
  omit,
  orderBy,
  partial,
  property,
  remove,
  union,
  identity,
  partition,
} from "lodash";
import React from "react";
import { getAppStatus } from "single-spa";
import { toUserName } from "src/users/users.helpers.js";
import { featureEnabled } from "feature-toggles!sofe";
import { hasAccess } from "cp-client-auth!sofe";
import { PREVIEWABLE_FILE_TYPES, fileEditingAllowedList } from "src/common/common.constants.js";

export function generateRenderDocuments(documents, newDocs = [], indentLevel = -1, parentIndex = null) {
  if (!(documents instanceof Array)) documents = [documents];
  return documents.reduce((renderDocuments, doc, index) => {
    const pathway = parentIndex === null ? "" : `${parentIndex}${parentIndex ? "." : ""}${doc.doc_type}s.${index}`;
    const renderDocument = {
      ...omit(doc, "folders", "files"),
      filesLength: doc.files ? doc.files.length : 0,
      hasFolders: doc.folders ? !!doc.folders.length : false,
      hasFiles: doc.files ? !!doc.files.length : false,
      indent_level: indentLevel,
      structurePath: pathway,
    };
    if (doc.id !== "0") renderDocument.client_seen = !~newDocs.map((nd) => nd.id).indexOf(doc.id);
    return [
      ...(doc.id === "0" || doc.id === "trash" ? renderDocuments : [...renderDocuments, renderDocument]),
      //Add folders if there are any and the folder is open
      ...(doc.folders && doc.is_open ? generateRenderDocuments(doc.folders, newDocs, indentLevel + 1, pathway) : []),
      //Add files if there are any and the folder is open
      ...(doc.files && doc.is_open ? generateRenderDocuments(doc.files, newDocs, indentLevel + 1, pathway) : []),
    ];
  }, []);
}

//This generates a flat list of all folders and files in document tree
//No documents are indented or nested
//All folders are closed when searching
export function generateFlatRenderDocuments(documents, newDocs = [], parentIndex = null) {
  if (!(documents instanceof Array)) documents = [documents];
  return documents.reduce((renderDocuments, doc, index) => {
    const pathway = parentIndex === null ? "" : `${parentIndex}${parentIndex ? "." : ""}${doc.doc_type}s.${index}`;
    const renderDocument = {
      ...omit(doc, "folders", "files"),
      filesLength: doc.files ? doc.files.length : 0,
      hasFolders: doc.folders ? !!doc.folders.length : false,
      hasFiles: doc.files ? !!doc.files.length : false,
      indent_level: 0,
      structurePath: pathway,
      is_open: false,
    };
    if (doc.id !== "0") renderDocument.client_seen = !~newDocs.map((nd) => nd.id).indexOf(doc.id);
    return [
      //accumulator
      ...renderDocuments,
      //add self?
      ...(doc.id === "0" || doc.id === "trash" ? [] : [renderDocument]),
      //Add folders if there are any
      ...(doc.folders ? generateFlatRenderDocuments(doc.folders, newDocs, pathway) : []),
      //Add files if there are any
      ...(doc.files ? generateFlatRenderDocuments(doc.files, newDocs, pathway) : []),
    ];
  }, []);
}

export function toSortedFlat(docs, sortField, sortDirection) {
  const docsClone = cloneDeep(docs);

  // Sort a flat list of files and folders (for search results)
  return [
    ...orderBy(
      docsClone.filter((doc) => doc.is_folder),
      (doc) => lowerCaseIt(get(doc, sortField)),
      sortDirection
    ),
    ...orderBy(
      docsClone.filter((doc) => !doc.is_folder),
      (doc) => lowerCaseIt(get(doc, sortField)),
      sortDirection
    ),
  ];
}

export function toSorted(sortField, documents, sortDirection) {
  let docsClone = cloneDeep(documents);
  const inbox = remove(docsClone.folders, (folder) => folder.id === "inbox");
  return {
    ...docsClone,
    folders: [
      ...(!isEmpty(inbox) && !isEmpty(inbox[0].files) ? inbox : []), // Inbox always comes first if non-empty
      ...(!isEmpty(docsClone.folders)
        ? orderBy(
            docsClone.folders.map((folder) => toSorted(sortField, folder, sortDirection)),
            (doc) => lowerCaseIt(get(doc, sortField)),
            sortDirection
          )
        : []),
    ],
    files: !isEmpty(docsClone.files)
      ? orderBy(docsClone.files, (doc) => lowerCaseIt(get(doc, sortField)), sortDirection)
      : null,
  };
}

function lowerCaseIt(arg) {
  if (typeof arg === `string`) return arg.toLowerCase();
  return arg;
}

export function toFullDocument(type, doc) {
  return {
    ...doc,
    doc_type: type,
    created_by_user: toUserName(doc.created_by_user_id),
    folders: doc.folders ? doc.folders.map(partial(toFullDocument, "folder")) : null,
    files: doc.files ? doc.files.map(partial(toFullDocument, "file")) : null,
  };
}

export function toTempDocument(file, name, tempId) {
  return {
    name: name,
    type: file.type,
    id: tempId,
  };
}

export function withToggledFolder(documents, { folder, open }) {
  let docsClone = cloneDeep(documents);
  let doc = get(docsClone, folder.structurePath);
  if (doc) {
    doc.is_open = open !== undefined ? open : !doc.is_open;
  }
  return docsClone;
}

export function renameDoc(documents, path, newName) {
  let docsClone = cloneDeep(documents);
  let doc = get(docsClone, path);
  if (doc) doc.name = newName;
  return docsClone;
}

export function withVisibilityUpdated(documents, paths, visible) {
  let docsClone = cloneDeep(documents);
  for (let path of paths) get(docsClone, path).is_visible = visible;
  return docsClone;
}

// A slower version for when we don't have the paths
export function withVisibilityUpdatedNoPaths(docsRoot, docs, visible) {
  let docsClone = cloneDeep(docsRoot);
  for (let doc of docs) {
    const foundDoc = searchDocsAt(docsClone, doc.id);
    //If they deleted the file this will come back false so we just skip it
    if (foundDoc) foundDoc.is_visible = visible;
  }
  return docsClone;
}

export function withAddedFolder(docsRoot, newFolder) {
  newFolder = toFullDocument("folder", newFolder);
  if (!newFolder.parent_folder_id || newFolder.parent_folder_id == 0) {
    return {
      ...(docsRoot || {}),
      folders: [...(docsRoot.folders || []), newFolder],
    };
  }
  let docsClone = cloneDeep(docsRoot);
  let parentFolder = searchDocsAt(docsClone, newFolder.parent_folder_id);
  parentFolder.folders = [...(parentFolder.folders ? parentFolder.folders : []), newFolder];
  return docsClone;
}

export function queueFiles(docsRoot, files, parentPath) {
  let newDocsRoot = cloneDeep(docsRoot);
  let parentFolder = !isEmpty(parentPath) ? get(newDocsRoot, parentPath) : newDocsRoot;
  parentFolder.is_open = true;
  parentFolder.files = (parentFolder.files || []).concat(
    files.map((file) => ({
      ...toFullDocument("file", toTempDocument(file.file, file.filename, file.uuid)), // Use the uuid as a temp ID to match uploaded files with queued files
      upload_status: "uploading",
    }))
  );
  return newDocsRoot;
}

export function updateFileData(docsRoot, parentPath, fileId, newData) {
  let newDocsRoot = cloneDeep(docsRoot);
  let parentFolder = !isEmpty(parentPath) ? get(newDocsRoot, parentPath) : newDocsRoot;
  const fileIndex = findIndex(parentFolder.files, (file) => file.id === fileId);
  const file = parentFolder.files[fileIndex];
  parentFolder.files[fileIndex] = { ...file, ...newData };
  return newDocsRoot;
}

export function updateDocsData(docsRoot, parentPath, docIds, newData, allDocs = [], movedFiles = []) {
  docsRoot = cloneDeep(docsRoot);
  const folders = cloneDeep(allDocs?.filter((doc) => doc.doc_type === "folder"));
  const parent = get(docsRoot, parentPath) || docsRoot;
  //all files are being moved to the same location
  //so we only need to find the new parent of the first match
  let foundParents = movedFiles?.find((file) => {
    const topLevel = findAllParents(file, folders);
    return topLevel.filter((item) => item).length > 0;
  });
  parent.files =
    parent.files &&
    parent.files.map((doc) => {
      if (docIds.find((moveId) => doc.id === moveId)) {
        const movedFile = movedFiles.find((movedDoc) => doc.id === movedDoc.id);

        return { ...doc, ...newData, ...movedFile };
      }
      return doc;
    });
  parent.folders =
    parent.folders &&
    parent.folders.map((doc) => {
      if (docIds.find((moveId) => doc.id === moveId)) {
        //if the folder being moved has a parent (it's not top level)
        //then remove any retention rules
        //if the folder being moved doesn't have a parent
        //then give it the id_path of 0 (make it top level)
        const movedFolder = movedFiles.find((movedDoc) => doc.id === movedDoc.id);
        return {
          ...doc,
          ...newData,
          ...(foundParents ? { retention_rule: null, id_path: movedFolder.id_path } : { id_path: "/0/" }),
        };
      }
      return doc;
    });
  return docsRoot;
}

export function withLoadedFiles(documents, folder, files) {
  let docsClone = cloneDeep(documents);
  let doc = get(docsClone, folder.structurePath);
  doc.files_loaded = true;
  doc.files = files.map(partial(toFullDocument, "file"));
  return docsClone;
}

// Searches docs starting at the given node
// maybe add some kind of "only search folders/files" option
// also only check open folders?
export function searchDocsAt(root, id) {
  if (root.id === id) return root;
  let children = [...(!isEmpty(root.files) ? root.files : []), ...(!isEmpty(root.folders) ? root.folders : [])];
  return children.reduce(partial(toFoundDoc, id), false);
}

function toFoundDoc(docId, foundDoc, doc) {
  if (foundDoc) return foundDoc;
  return searchDocsAt(doc, docId);
}

export function findDocWithStructurePath(node, id, path = "") {
  if (node.id === id) return { ...node, structurePath: path };
  const foundFileIndex = findIndex(node.files, (file) => file.id === id);
  if (foundFileIndex !== -1)
    return { ...node.files[foundFileIndex], structurePath: `${path ? `${path}.` : ""}files.${foundFileIndex}` };
  if (!node.folders) return false;
  for (let i = 0; i < node.folders.length; i++) {
    const currentPath = `${path ? `${path}.` : ""}folders.${i}`;
    const foundDoc = findDocWithStructurePath(node.folders[i], id, currentPath);
    if (foundDoc) return foundDoc;
  }
  return false;
}

export function pathsToDocs(docsRoot, paths, searching) {
  //When searching, docsRoot is flat array
  if (searching) return paths.map((path) => find(docsRoot, (doc) => doc.structurePath === path));
  //When not searching docsRoot is a tree structure
  return paths.map((path) => ({ ...get(docsRoot, path), structurePath: path }));
}

export function rangeSelect(renderDocuments, selectedDocs, endIndex) {
  const startIndex = findIndex(renderDocuments, ({ structurePath }) => structurePath === last(selectedDocs));
  const range = renderDocuments
    .slice(startIndex > endIndex ? endIndex : startIndex, startIndex > endIndex ? startIndex : endIndex + 1)
    .map(property("structurePath"));
  return union(selectedDocs, range);
}

export function toNoUnneededChildren(selectedDocs) {
  return selectedDocs.filter((doc) => !find(selectedDocs, (path) => path !== doc && !doc.indexOf(path + ".")));
}

export function refreshDocsFromState(filesInState, files) {
  return files.map((file) => filesInState.find((f) => f.id === file.id));
}

// if parentPath is null, move docs to root.
// if parentPath is "remove", just remove the docs
export function moveDocs(docsRoot, selectedDocs, parentPath, parentId) {
  const newDocsRoot = cloneDeep(docsRoot);
  const moveTo = !isEmpty(parentPath) && parentPath !== "remove" ? get(newDocsRoot, parentPath, {}) : newDocsRoot;
  parentId = parentId || moveTo?.id;
  moveTo.is_open = true;
  selectedDocs.forEach((path) => {
    const pathArr = path.split(".");
    const cutIndex = pathArr.pop();
    const cutArr = get(newDocsRoot, pathArr);
    const cutDoc = { ...cutArr[cutIndex], parent_folder_id: parentId, upload_status: "uploading" };
    cutArr[cutIndex] = null;
    const docType = pathArr.pop();
    if (parentPath !== "remove") moveTo[docType] = (moveTo[docType] || []).concat([cutDoc]);
  });
  return removeDocHoles(newDocsRoot);
}

export function removeDocHoles(docs) {
  return {
    ...docs,
    folders: !docs.folders
      ? []
      : docs.folders.reduce((folders, folder) => {
          if (!folder) return folders;
          return [...folders, removeDocHoles(folder)];
        }, []),
    files: !docs.files ? [] : docs.files.filter((doc) => doc),
  };
}

// Given a list of selected docs, tells what actions we can perform on them
// Possible actions (in documents.component.js): delete, download, makeVisible, removeVisible, info, download, rename, move
export function getSelectionActionKeys(selectedDocs, isClientPortal, isSearching, loggedInUser, betas) {
  if (isEmpty(selectedDocs)) {
    return [];
  }

  const hasFolders = selectedDocs.some((doc) => doc.doc_type === "folder");
  const isOnlyFolders = selectedDocs.every((doc) => doc.doc_type === "folder");
  const allVisible = !selectedDocs.some((doc) => !doc.is_visible);
  const isSingleFileOrFolder = selectedDocs.length === 1;
  const isTopLevelFolder = isSingleFileOrFolder && selectedDocs.every((doc) => doc.id_path === "/0/");
  const containsZipFile = selectedDocs.some((doc) => getExt(doc.name) === "zip");
  const selectionIsEsignable = selectedDocs.every((doc) => PREVIEWABLE_FILE_TYPES[getExt(doc.name)]);
  const hasAccessToEsign = hasAccess(loggedInUser, true)(["tasks_esign", "tasks_create_edit"]);
  const hasChangeFilesAccess = hasAccess(loggedInUser)("files_upload_move");
  const canToggleVisibility = hasAccess(loggedInUser)("files_client_portal_visibility");
  const canShareSecureFiles = hasAccess(loggedInUser)("files_share");
  const userCanEditFiles = hasAccess(loggedInUser)("files_edit");
  //If you can unlock you can lock
  const userCanLock = hasAccess(loggedInUser)("files_unlock");
  const fileIsAllowedToBeEdited = selectedDocs.some((doc) => fileEditingAllowedList.includes(getExt(doc.name)));
  const isLocked = selectedDocs.some((doc) => doc.locked);

  // What you can do to the selected files
  const canESign =
    !isLocked && !isClientPortal && isSingleFileOrFolder && !hasFolders && selectionIsEsignable && hasAccessToEsign;
  const canUnzip = !isClientPortal && isSingleFileOrFolder && containsZipFile;
  const canDownload = !isClientPortal || !hasFolders;
  const canPreview = isSingleFileOrFolder && !hasFolders && !containsZipFile;
  const canShareFile = !isClientPortal && !hasFolders && !containsZipFile && canShareSecureFiles;
  const canShareSecureFile =
    !isClientPortal && !hasFolders && !containsZipFile && canShareSecureFiles && isSingleFileOrFolder;
  const canCopyFile = !isClientPortal && isSingleFileOrFolder && !hasFolders && !containsZipFile && canToggleVisibility;
  const canViewInfo = !isClientPortal && isSingleFileOrFolder && !hasFolders;
  const canChangeVisibility = !isClientPortal && !hasFolders;
  const canMakeVisible = canChangeVisibility && !allVisible && canToggleVisibility;
  const canRemoveVisibility = canChangeVisibility && allVisible && canToggleVisibility;
  const canRename = !isLocked && !isClientPortal && isSingleFileOrFolder && hasChangeFilesAccess;
  const canMoveFile = !isLocked && !isClientPortal && !hasFolders && hasChangeFilesAccess;
  const canMoveFolder = !isClientPortal && hasFolders && hasChangeFilesAccess;
  const canDeleteFile = !isLocked && !isClientPortal && hasAccess(loggedInUser)("files_archive");
  const canLocateInFileTree = isSearching && isSingleFileOrFolder;
  const canEdit =
    !isLocked &&
    !isClientPortal &&
    !hasFolders &&
    isSingleFileOrFolder &&
    !containsZipFile &&
    userCanEditFiles &&
    fileIsAllowedToBeEdited;
  const canUnlock = isLocked && !isClientPortal && isSingleFileOrFolder;
  const canLock =
    !isLocked && !isClientPortal && !hasFolders && isSingleFileOrFolder && userCanLock && featureEnabled("manual_lock");
  const canMerge =
    !isLocked && !isClientPortal && !isSingleFileOrFolder && !hasFolders && hasAccess(loggedInUser)("files_edit");
  const canChangePermissions =
    !isClientPortal && isOnlyFolders && isSingleFileOrFolder && hasAccess(loggedInUser)("files_permissions");
  const canAddRetentionRule =
    isTopLevelFolder &&
    isOnlyFolders &&
    isSingleFileOrFolder &&
    !isClientPortal &&
    hasAccess(loggedInUser)("files_retention_rules_create_edit");

  return [
    canESign && "eSign",
    canEdit && "editFile",
    canUnlock && "unlockFile",
    canLock && "lockFile",
    canUnzip && "unzip",
    canDownload && "download",
    canPreview && "preview",
    canShareFile && "shareFile",
    canShareSecureFile && "shareSecureFile",
    canCopyFile && "copyFile",
    canViewInfo && "info",
    canMakeVisible && "makeVisible",
    canRemoveVisibility && "removeVisible",
    canRename && "rename",
    canMoveFile && "moveFile",
    canMerge && "mergeFiles",
    canMoveFolder && "moveFolder",
    canDeleteFile && "delete",
    canLocateInFileTree && "locateInFileTree",
    canChangePermissions && "teamMemberPermissions",
    canAddRetentionRule && "retentionRule",
  ].filter(identity);
}

// Looks up which file we uploaded
// files: all the files we are in the process of uploading
// doc: the response from the server after we've uploaded it
export function getUploadedFileTempId(files, doc) {
  let uploadedFileTempId;
  const matchingFile = files.find((file) => file.filename === doc.name);

  if (matchingFile) return matchingFile.uuid;

  // We know something was renamed on the server, so get the first one
  const matchingFiles = files.filter((file) => noExt(doc.name).startsWith(noExt(file.filename)));
  if (matchingFiles.length) return matchingFiles[0].uuid;

  return null;
}

export function noExt(filename) {
  return filename.indexOf(".") === -1 ? filename : filename.split(".").slice(0, -1).join(".");
}

export function getExt(filename) {
  if (isEmpty(filename)) return "";
  const pathParts = filename.split(".");
  return pathParts.length >= 2 ? pathParts.pop().toLowerCase() : "";
}

export function truncateFilename(name) {
  const ext = getExt(name);
  // The rename input is limited to 64, we will truncate to 58 to allow duplicate filename tickers up to (999)
  return noExt(name).substring(0, 58) + (ext ? "." + ext : "");
}

export function regetDoc(getState, id) {
  if (id === "0") return getState().documents.documents;
  return findDocWithStructurePath(getState().documents.documents, id);
}

export function isDocsActive() {
  return getAppStatus("docs-ui") === "MOUNTED";
}

export function showArrow(doc) {
  return (doc.has_children && !doc.files_loaded) || doc.hasFolders || doc.hasFiles;
}

export function filterDocsBySearchVal(documents, searchVal) {
  return documents.filter((doc) => doc.name.toLowerCase().indexOf(searchVal.toLowerCase()) > -1);
}

export function flattenFolders(folders) {
  if (!folders.length) return folders;

  return [folders[0], ...flattenFolders(folders[0].folders), ...flattenFolders(folders.slice(1))];
}

export function docSearchHighlight(string, term) {
  if (!term || !string) return string;
  term = term.toLowerCase();
  const stop = string.toLowerCase().indexOf(term);
  if (stop === -1) return string;
  const bold = string.slice(stop, stop + term.length);
  const nonBold = string.slice(0, stop);
  const rest = string.slice(stop + term.length, string.length);
  return (
    <span>
      {nonBold}
      <strong className="cps-color-primary">{bold}</strong>
      {docSearchHighlight(rest, term)}
    </span>
  );
}

export function idPathToIdArray(path) {
  if (!path) return [];
  return path?.split("/").filter((id) => !!id);
}

export function findAllParents(doc, allFolders) {
  if (!doc.id_path) return [];
  return idPathToIdArray(doc.id_path)
    .filter((id) => id !== "0")
    .map((id) => find(allFolders, (folder) => folder.id === id));
}

export function addFilesToDocTreeByIdPath(files, documents) {
  let docs = cloneDeep(documents);
  let fs = files.map(partial(toFullDocument, "file"));

  fs.forEach((file) => {
    const idPathArr = idPathToIdArray(file.id_path);
    if (!idPathArr.length) return;
    let currentLevel = docs;

    idPathArr.forEach((id) => {
      let temp = currentLevel.folders.find((folder) => folder.id === id);
      if (!temp) return;
      currentLevel = temp;
    });

    if (currentLevel.id === idPathArr[idPathArr.length - 1]) {
      if (!currentLevel.files) currentLevel.files = [];
      let i = currentLevel.files.map((f) => f.id).indexOf(file.id);
      if (~i) {
        currentLevel.files.splice(i, 1, file);
      } else {
        currentLevel.files.push(file);
      }
    }
  });
  return docs;
}

export function createLinkForFile(fileIds, clientId) {
  fileIds = fileIds.join(",");
  return `${canopyUrls.getWebUrl()}/#/docs/clients/${clientId}/files?selected_file=${fileIds}`;
}

export function createLinkForFileClients(fileIds, clientId, whiteListDomain) {
  let env = canopyUrls.getEnvironment();
  const queryParam = fileIds.length !== 1 ? "" : `?selected_file=${fileIds[0]}`;

  if (whiteListDomain) {
    return `https://${whiteListDomain}.clientportal.${
      env === "integration" || env === "staging" ? "ninja" : "com"
    }/m/clients/${clientId}/files/${queryParam}`;
  } else {
    return `${canopyUrls.getWebUrl()}/m/clients/${clientId}/files/${queryParam}`;
  }
}

export function scrollToElement(element, extraPaddingBottom = 16, extraPaddingTop = 0) {
  setTimeout(() => {
    if (typeof element === "function") {
      element = element();
    }
    const container = document.getElementById("scroll-area");
    if (!container || !element) return;
    const visibleArea = container.clientHeight;
    const areaAbove = container.scrollTop;
    const pos = element.offsetTop;

    //Element is to low, scroll down
    if (pos + element.clientHeight + extraPaddingBottom - areaAbove > visibleArea) {
      container.scrollTop = pos - visibleArea + element.clientHeight + extraPaddingBottom - 64;
    }
    //Element is too high, scroll up
    else if (pos - extraPaddingTop < areaAbove) {
      container.scrollTop = pos - extraPaddingTop;
    }
  }, 100);
}

export function generateContentsLabel(docs) {
  const [folders, files] = partition(docs, ["doc_type", "folder"]);
  const hasFiles = !!files.length;
  const hasFolders = !!folders.length;

  if (hasFolders && hasFiles) {
    return "items";
  } else if (hasFolders && !hasFiles) {
    return folders.length > 1 ? "folders" : "folder";
  } else if (!hasFolders && hasFiles) {
    return files.length > 1 ? "files" : "file";
  }
}
