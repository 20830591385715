import { truncateFilename } from "src/documents/documents.helpers.js";
import { uuid } from "src/common/helpers.js";
import { fetchAsObservable } from "fetcher!sofe";
import { catchError, map, pluck, switchMap } from "rxjs/operators";
import { handleError } from "src/handle-error.helper";
import { warningToast } from "toast-service!sofe";
import { getS3Path, createS3FileName } from "src/documents/documents.resource";

export function getListOfFilesInFolder(folderId) {
  // api/docs/folders/{file_id_of_folder}/files
  return fetchAsObservable(`/api/docs/folders/${folderId}/files?includes=name_path,s3_path`);
}

export function getSingleClientInboxFiles(clientIdOrFolder) {
  const query = clientIdOrFolder === "tenant" ? `inbox_for=` : `client_id=`;
  return fetchAsObservable(
    `/api/docs/folders/INBOX/files?includes=name_path&sort=-created_at&${query}${clientIdOrFolder}`
  ).pipe(pluck("files"));
}

export function getFileMeta(fileId, includeOptions = {}) {
  // GET /api/docs/files/:id
  const includesList = [
    includeOptions.includePreview && "preview_links",
    includeOptions.includeAnnotations && "annotations",
  ].filter((item) => item);

  const includes = includesList.length ? `?includes=${includesList.join(",")}` : "";
  return fetchAsObservable(`/api/docs/files/${fileId}${includes}`).pipe(map((resp) => resp.file));
}

export function deleteFiles(fileIds) {
  // api/docs/files:batch_delete
  return fetchAsObservable(`/api/docs/files:batch-delete`, {
    method: "POST",
    body: { file_ids: fileIds },
  });
}

export function createFolder(parentFolderId, folderName, permissions) {
  // api/docs/folders/{file_id_of_folder}/folders
  return fetchAsObservable(`/api/docs/folders/${parentFolderId}/folders`, {
    method: "POST",
    body: { folder_file: { name: folderName, permissions: permissions } },
  });
}

export function patchFile(fileId, fileData) {
  // api/docs/files/{file_id}
  return fetchAsObservable(`/api/docs/files/${fileId}`, {
    method: "PATCH",
    body: { file: fileData },
  });
}
export function getFilePermissions(fileId) {
  // api/docs/files/{file_id}/permissions
  return fetchAsObservable(`/api/docs/files/${fileId}/permissions`);
}

export function postFilePermissions(fileId, filePermissions) {
  // api/docs/files/{file_id}/permissions
  return fetchAsObservable(`/api/docs/files/${fileId}/permissions`, {
    method: "POST",
    body: { permissions: filePermissions },
  });
}

export function patchFilePermissions(fileId, filePermissions) {
  // api/docs/files/{file_id}/permissions
  return fetchAsObservable(`/api/docs/files/${fileId}/permissions`, {
    method: "PATCH",
    body: { permissions: filePermissions },
  });
}

export function getRecentlyViewedFiles(excludeClientFiles, restrictToOneClient) {
  return globalFilesSearch(null, null, null, true, excludeClientFiles, restrictToOneClient);
}

export function globalFilesSearch(
  folderId,
  searchVal,
  searchDeleted,
  recents,
  excludeClientFiles,
  restrictToOneClient,
  clientId = null
) {
  // if we have a clientId and are on the main client folder use include_related_clients flag and pass clientId
  folderId =
    !!clientId && folderId === "CON"
      ? `folder_id=CON${clientId}&include_related_clients=true`
      : folderId
      ? `folder_id=${folderId}`
      : "";

  searchVal = searchVal ? `search_term=${searchVal}` : "";
  searchDeleted = searchDeleted ? `only_deleted=${searchDeleted}` : "";
  recents = recents ? `recent=true` : "";
  excludeClientFiles = excludeClientFiles ? `client_id_plus=exclude_clients` : "";
  restrictToOneClient = restrictToOneClient ? `client_id_plus=CON${restrictToOneClient}` : "";

  const queryArr = [folderId, searchVal, searchDeleted, recents, excludeClientFiles, restrictToOneClient].filter(
    (query) => !!query
  );
  const queryStr = queryArr ? `?${queryArr.join("&")}` : "";

  // api/docs/search
  return fetchAsObservable(`/api/docs/search${queryStr}`).pipe(pluck("files"));
}

export function patchFileName(fileId, newName) {
  return patchFile(fileId, { name: newName });
}

export function patchFileDescription(fileId, description) {
  return patchFile(fileId, { description: description });
}

export function moveFiles(fileIds, targetFolder, excludeNamePath = null, allowCrossClient = false) {
  // api/docs/files:move
  return fetchAsObservable(`/api/docs/files:move?includes=${excludeNamePath ? "" : "name_path"}`, {
    method: "POST",
    body: {
      files: {
        allow_cross_client: allowCrossClient,
        file_ids: fileIds,
        new_parent_id: targetFolder,
      },
    },
  });
}

export function patchFileVisibility(fileIds, visible) {
  // api/docs/files:batch-visibility
  return fetchAsObservable(`/api/docs/files:batch-visibility`, {
    method: "POST",
    body: { files: { file_ids: fileIds, is_visible: visible } },
  });
}

export function createZip(fileIds) {
  // api/docs/zips
  return fetchAsObservable(`/api/docs/zips`, {
    method: "POST",
    body: { file_ids: fileIds },
  }).pipe(
    map((res) => res.hash),
    catchError(handleError)
  );
}

export function getZip(zipId) {
  // api/docs/zips/{zip_id}
  return fetchAsObservable(`/api/docs/zips/${zipId}`);
}

export function unZipFile(fileId) {
  // api/docs/files/{file_id}:extract
  return fetchAsObservable(`/api/docs/files/${fileId}:extract`, {
    method: "POST",
  });
}

export function getDeletedFiles(rootFolderId, page = null, limit = null) {
  // /api/docs/trash/{file_id_of_folder}
  let query = "";
  if (page && limit) {
    query = `?paginate=true&page=${page}&limit=${limit}`;
  }
  return fetchAsObservable(`/api/docs/trash/${rootFolderId}${query}`);
}

function getS3UploadUrl(folder) {
  return fetchAsObservable(`/api/docs/folders/${folder?.id || folder}/s3`).pipe(map((resp) => resp.s3));
}

function uploadFileMeta(parentFolder, file, s3Data, inbox, hidden, days_until_expiration, visible_to_client = false) {
  const s3FileName = createS3FileName(file);
  const pathToS3 = getS3Path(s3Data.fields.key, s3FileName);
  let fileData = {
    name: file.filename,
    path_to_s3: pathToS3,
    description: "",
    mimetype: file.file?.type || file.file?.mimetype || "other",
    is_visible: visible_to_client,
    filesize: file.file?.size || file.file?.filesize || 0,
    inbox,
    hidden,
    ...(days_until_expiration && { days_until_expiration: days_until_expiration }),
  };

  return fetchAsObservable(`/api/docs/folders/${parentFolder?.id || parentFolder}/files`, {
    method: "POST",
    body: `{"file" : ${JSON.stringify(fileData)}}`,
  }).pipe(
    catchError((error) => {
      throw new Error(
        `Problem storing uploaded file, ${JSON.stringify(
          fileData
        )}. This file is stored now in s3, but not in DDG. Err ${JSON.stringify(error)}`
      );
    })
  );
}

function uploadToS3(file, s3Data) {
  // For s3 we change the file name to include the uuid
  const otherFields = s3Data.fields;
  const s3FileName = createS3FileName(file);
  const s3Path = getS3Path(otherFields.key, s3FileName);
  const uploadFields = { ...otherFields, key: s3Path };

  // s3 needs the body in form data
  const formData = new FormData();
  for (let field in uploadFields) formData.append(field, uploadFields[field]);
  formData.append("file", file.file);
  return fetchAsObservable(s3Data.url, {
    method: "POST",
    credentials: "omit",
    body: formData,
  }).pipe(
    map((resp) => file),
    catchError((error) => {
      error.toastMessage = `Failed to upload file "${file.filename}"`;
      handleError(error);
    })
  );
}

export function uploadFiles(config) {
  const { files = [], folderId, inbox, hidden, callback, days_until_expiration, visible_to_client = false } = config;
  const tweakedFiles = files.map((file) => ({ file: file, uuid: uuid(), filename: truncateFilename(file.name) }));
  return filesUploadAsync(
    tweakedFiles,
    { id: folderId },
    inbox,
    callback,
    hidden,
    days_until_expiration,
    visible_to_client
  );
}

export async function filesUploadAsync(
  filesAndUuids,
  parentFolder,
  inbox,
  uploadedFilesCallback = () => {},
  hidden = false,
  days_until_expiration,
  visible_to_client
) {
  //POST files to S3
  if (filesAndUuids.length) {
    //Request information from S3 about where to upload file(s)
    const s3Data = await new Promise((resolve, reject) => {
      getS3UploadUrl(parentFolder).subscribe(
        (s3Info) => resolve(s3Info),
        (err) =>
          reject(
            new Error(`Failed to get s3 upload info for folder "${parentFolder.name}." Err: ${JSON.stringify(err)}`)
          )
      );
    });

    const s3Response = await Promise.all(
      filesAndUuids.map(async (file) => {
        return await new Promise((resolve, reject) => {
          uploadToS3(file, s3Data).subscribe(
            (resp) => resolve({ ...resp, uuid: file.uuid }),
            (err) => {
              warningToast(`Failed to upload file "${file.filename}"`);
              reject(err);
            }
          );
        }).catch((error) => {
          error.showToast = false;
          handleError(error);
        });
      })
    );

    //If the s3 process was a success, then POST file metadata
    return await Promise.all(
      s3Response
        .filter((f) => !!f)
        .map(async (file) => {
          const uploadedFile = await new Promise((resolve, reject) => {
            uploadFileMeta(
              parentFolder,
              file,
              s3Data,
              inbox,
              file.hidden || hidden,
              days_until_expiration,
              visible_to_client
            ).subscribe(
              (resp) => resolve({ ...resp.file, uuid: file.uuid }),
              (err) => {
                warningToast(`Failed to upload file "${file.filename}"`);
                reject(err);
              }
            );
          }).catch((error) => {
            error.showToast = false;
            handleError(error);
          });
          const s3FileName = createS3FileName(file);
          const pathToS3 = getS3Path(s3Data.fields.key, s3FileName);
          let returnedUploadedFile = { ...uploadedFile, path_to_s3: pathToS3 };
          uploadedFilesCallback([returnedUploadedFile]);
          return returnedUploadedFile;
        })
    );
  }
}

export function getInboxCount() {
  // api/docs/inbox_count
  return fetchAsObservable(`/api/docs/inbox_count`);
}

export async function purgeFiles(file_ids) {
  // api/docs/files:purge
  try {
    const response = await fetchAsObservable(`/api/docs/files:purge`, {
      method: "POST",
      body: { file_ids },
    }).toPromise();
    return response;
  } catch (error) {
    error.toastMessage =
      error?.data?.errors?.message === "This file is linked to another entity (e.g. a Task), preventing deletion"
        ? error.data.errors.message
        : "An error occurred while permanently deleting your file. Please try again.";
    return handleError(error);
  }
}

export function copyFiles(fileIds, folderId = "0", hidden = false, inbox = false) {
  const body = {
    files: {
      file_ids: fileIds,
      new_parent_id: folderId,
      hidden,
      inbox,
    },
  };

  return fetchAsObservable(`/api/docs/files:copy`, {
    method: "POST",
    body,
  });
}

const createClientList = (jql) => {
  return fetchAsObservable(`/api/client_lists`, {
    method: "POST",
    body: { data: { jql } },
  }).pipe(pluck("snapshot_id"), catchError(handleError));
};

export function bulkCopyFiles(file_ids, jql) {
  return createClientList(jql).pipe(
    switchMap((snapshot_id) =>
      fetchAsObservable(`/api/docs/files:batch-copy`, {
        method: "POST",
        body: {
          files: { client_list_id: snapshot_id, file_ids: file_ids },
        },
      })
    )
  );
}
