import { take, map } from "rxjs/operators";
import { successToast, warningToast, infoToast } from "toast-service!sofe";
import { fetchAsObservable, onPusher } from "fetcher!sofe";
import { modalService } from "canopy-styleguide!sofe";
import auth from "cp-client-auth!sofe";
import FileLockedModal from "./modals/file-locked-modal.component";
import FileUnlockModal from "./modals/file-unlock-modal.component";
import DesktopAssistantOffModal from "./modals/desktop-assistant-off-modal.component";
import DesktopAssistantSignedOut from "./modals/desktop-assistant-signed-out-modal.component";
import DesktopAssistantErrorModal from "./modals/desktop-assistant-error-modal.component";
import AnnotationsLostModal from "./annotations-lost-modal.component";
import { isPdf } from "./mime-types.helpers";
import { captureException } from "error-logging!sofe";
import { forEach } from "lodash";
import { track } from "cp-analytics";
import { handleError } from "../handle-error.helper";

let discoveredPort = null;

async function buildBootsUrl() {
  const url = (p) => `http://localhost:${p}`;
  if (discoveredPort) return url(discoveredPort);
  const portRange = [45032, 45041];
  for (let i = 0; i < portRange[1] - portRange[0]; i++) {
    const port = portRange[0] + i;
    const res = await fetch(`${url(port)}/check`);
    if (res.status === 200) {
      discoveredPort = port;
      break;
    }
  }
  if (!discoveredPort) throw Error("No Canopy Desktop Assistant Server");
  return url(discoveredPort);
}

export async function unlockFile(doc) {
  try {
    const user = await auth.getLoggedInUserAsObservable().pipe(take(1)).toPromise();
    const userCanUnlock = auth.hasAccess(user)("files_unlock");
    const selfLocked = doc.locked_by_user_id === user.id;
    if (selfLocked || userCanUnlock) {
      if (await modalService.render(FileUnlockModal, { selfLocked, file: doc }, { width: 500 })) {
        await fetchAsObservable(`/api/docs/files/${doc.id}`, {
          method: "PATCH",
          body: { file: { locked: false } },
        }).toPromise();
        successToast("Canopy Desktop Assistant: File unlocked!");
      }
    } else {
      await modalService.render(FileLockedModal, { file: doc }, { width: 500 });
    }
  } catch (e) {
    e.showToast = false;
    console.error(e);
    captureException(e);
    warningToast(`Unable to unlock file.`);
  }
}

export async function lockFile(doc) {
  try {
    const user = await auth.getLoggedInUserAsObservable().pipe(take(1)).toPromise();
    await fetchAsObservable(`/api/docs/files/${doc.id}`, {
      method: "PATCH",
      body: { file: { locked: true } },
    }).toPromise();
    successToast("File has been locked.");
  } catch (e) {
    e.showToast = false;
    console.error(e);
    captureException(e);
    warningToast(`Unable to lock file.`);
  }
}

export async function editFile(doc) {
  try {
    //TODO: someday migrate off rxjs v6 and update from toPromise()
    const res = await getFileDownloadLink(doc.id).toPromise();
    if (res?.status !== "complete") {
      warningToast("File not yet ready to edit, please retry.");
      return;
    }
  } catch (e) {
    handleError(e);
  }

  if (!isPdf(doc.type || doc.mimetype) && !(await annotationsWarning(doc.id))) return;

  let baseUrl;
  try {
    baseUrl = await buildBootsUrl();
  } catch (e) {
    infoToast({
      headerText: "Opening Canopy Desktop Assistant",
      message:
        "You are being redirected to the Canopy Desktop Assistant app. If you do not have the app please download it or contact support.",
      durationInMillis: 30000,
      links: [{ url: "https://www.getcanopy.com/desktop-assistant", label: "Download" }],
    });
    // if building the url throws, then try to send a message to "canopy" protocol
    location.href = `canopy://editing?fileId=${doc.id}`;
    return;
  }

  try {
    successToast("Opening file in Canopy Desktop Assistant...");
    const res = await fetch(`${baseUrl}/editing/${doc.id}`);
    const { editing } = await res.json();
    if (res.status === 200 && editing?.message) {
      successToast(editing.message);
    } else if (res.status === 503) {
      const shouldFocusDA = await modalService.render(DesktopAssistantSignedOut, {}, { width: 500 });
      if (shouldFocusDA) focusDesktopAssistant();
    } else {
      modalService.render(DesktopAssistantErrorModal, {}, { width: 500 });
    }
  } catch (e) {
    modalService.render(DesktopAssistantOffModal, {}, { width: 500 });
  }
}

export async function focusDesktopAssistant() {
  let baseUrl;
  try {
    baseUrl = await buildBootsUrl();
  } catch (e) {
    location.href = `canopy://focus`;
    return;
  }
  return await fetch(`${baseUrl}/focus`);
}

export async function annotationsWarning(fileId) {
  try {
    const { file } = await fetchAsObservable(`/api/docs/files/${fileId}?includes=annotations`).toPromise();
    if (file?.annotations?.length) {
      return await modalService.render(AnnotationsLostModal, {}, { width: 500 });
    }
    return true;
  } catch (e) {
    e.showToast = false;
    captureException(e);
    warningToast(`An error occurred while trying to read the file.`);
    return false;
  }
}

// Current available option fields:
// include - ['annotations', 'preview_links']
export function getFileMeta(fileId, options) {
  const queryString = fileMetaQueryString(options);
  return fetchAsObservable(`/api/docs/files/${fileId}${queryString}`).pipe(map((resp) => resp.file));
}

export function fileMetaQueryString(options = {}) {
  let queryString = "?";
  if (options.include?.length) {
    const include = options.include.sort().join(",");
    queryString += `includes=${include}`;
  }

  return queryString.length > 1 ? queryString : "";
}

// parentFolderId - id of the folder where the file's meta data will live in files service
// file: from the File prototype - new File([<filebits>], '<filename>', { type: '<filetype>'} )
export async function uploadFileToS3(parentFolderId, file) {
  const response = await fetchAsObservable(`/api/docs/folders/${parentFolderId}/s3`).toPromise();
  const s3Data = response.s3;
  s3Data.fields.key = s3Data.fields.key.replace("${filename}", file.name);
  const formData = new FormData();
  forEach(s3Data.fields, (data, key) => {
    formData.append(key, data);
  });
  formData.append("file", file);
  const s3Response = await fetchAsObservable(s3Data.url, {
    credentials: "omit",
    method: "POST",
    body: formData,
  }).toPromise();
  return s3Data.fields.key;
}

// fileMeta: object containing file's id and parent_folder_id
// file: from the File prototype - new File([<filebits>], '<filename>', { type: '<filetype>'} )
export async function uploadPageManipulation(fileMeta, file) {
  const s3Path = await uploadFileToS3(fileMeta.parent_folder_id, file);

  // First we start listening to the channel here so we don't miss the
  // push event to let us know it's done.
  const socketPromise = new Promise((resolve, reject) => {
    const manipulateSub = onPusher("docs").subscribe((push) => {
      if (push.event_type === "manipulate" && push.file_id === fileMeta.id) {
        if (push.data.status === "complete") {
          resolve();
          manipulateSub.unsubscribe();
        } else if (push.data.status === "error") {
          reject(new Error(push.data.error_message));
          manipulateSub.unsubscribe();
        }
      }
    }, handleError);
  });

  track("practice_management", "files", "page_manipulation");

  // Then we make the request to let the backend know we've uploaded
  // the new manipulated PDF to S3. This request will return quickly
  // with a process id, but what we're really waiting for is the push
  // event so we don't even need to await this request.
  fetchAsObservable(`/api/docs/files/${fileMeta.id}/manipulate`, {
    method: "POST",
    body: { file: { path_to_s3: s3Path } },
  })
    .toPromise()
    .catch((error) => {
      // If the api fails then we don't want to be waiting forever for
      // a push event that isn't coming.
      throw error;
    });

  // Once the push event comes through then the page manipulation is
  // actually finished so we await the promise that resolves when that
  // push comes through.
  await socketPromise;
}

export async function patchFile(fileId, update) {
  return fetchAsObservable(`/api/docs/files/${fileId}`, {
    method: "PATCH",
    body: {
      file: update,
    },
  }).toPromise();
}

export function patchFileVisibility(fileIds, visible) {
  return fetchAsObservable(`/api/docs/files:batch-visibility`, {
    method: "POST",
    body: { files: { file_ids: fileIds, is_visible: visible } },
  }).toPromise();
}

export async function getFilesInFolder(folderId) {
  const response = await fetchAsObservable(`/api/docs/folders/${folderId}/files`).toPromise();
  return response.files;
}

export function getWhiteLabelSettings() {
  return fetchAsObservable(`/white-label-settings`);
}

export function getFileDownloadLink(fileId) {
  // GET /api/docs/files/:id/download
  // NOTE: this is used for all files (including readonly files)
  // if this is ever updated to include anything else on the file download (like annotations)
  // then we may need to account for readonly files which should not include annotations on download
  return fetchAsObservable(`/api/docs/files/${fileId}/download?include_fields=true`);
}

export function getFilePrintLink(fileId) {
  // GET /api/docs/files/:id/print
  // NOTE: this is used for all files (including readonly files)
  // if this is ever updated to include anything else on the file download (like annotations)
  // then we may need to account for readonly files which should not include annotations on download
  return fetchAsObservable(`/api/docs/files/${fileId}/print?include_fields=true`);
}
